import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Surf's Up Camp
			</title>
			<meta name={"description"} content={"Злови хвилю свого життя!"} />
			<meta property={"og:title"} content={"Головна | Surf's Up Camp"} />
			<meta property={"og:description"} content={"Злови хвилю свого життя!"} />
			<meta property={"og:image"} content={"https://smartwaysbin.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://smartwaysbin.com/img/214353263462.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://smartwaysbin.com/img/214353263462.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://smartwaysbin.com/img/214353263462.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://smartwaysbin.com/img/214353263462.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://smartwaysbin.com/img/214353263462.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://smartwaysbin.com/img/214353263462.jpeg"} />
			<meta name={"msapplication-TileColor"} content={"https://smartwaysbin.com/img/214353263462.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section overflow-x="hidden" overflow-y="hidden" quarkly-title="Hero-6">
			<Override slot="SectionContent" width="100%" flex="1 1 0%" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				position="static"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="140px 80px 140px 80px"
					position="static"
					lg-width="100%"
					lg-padding="80px 30px 80px 30px"
					sm-padding="80px 30px 40px 30px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
					Surf's Up Camp
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead">
					Ви готові осідлати хвилі та відчути гострі відчуття від серфінгу? У Surf's Up Camp ми пропонуємо незабутню пригоду як для початківців, так і для досвідчених серферів. Наша місія - забезпечити першокласне навчання серфінгу, безпеку та захоплюючий досвід, який змусить вас повертатися знову і знову.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-primary"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							border-width="2px"
							border-style="solid"
							border-color="--color-primary"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							transition="background-color 0.2s ease 0s"
							type="link"
							href="/contacts"
							text-decoration-line="initial"
						>
							Контакти
						</Button>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="758px"
					display="flex"
					margin="0px -50px 0px 0px"
					position="static"
					bottom="-298px"
					height="716px"
					left="614px"
					right="-52px"
					top="-28px"
					flex="1 1 0%"
					lg-margin="0px 0 0px 0px"
					lg-align-self="center"
					lg-padding="0px 0px 80px 0px"
					sm-flex="1 1 0%"
					sm-align-self="auto"
					sm-width="100%"
					sm-padding="0px 0px 40px 0px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						margin="80px 0 0 25px"
						width="33%"
						lg-margin="80px 0 0 0"
						sm-margin="50px 0 0 0"
					>
						<Image
							src="https://smartwaysbin.com/img/2.jpg"
							display="block"
							height="300px"
							border-radius="25px"
							object-fit="cover"
							width="100%"
							sm-height="200px"
							sm-width="200px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						flex-direction="column"
						justify-content="center"
						margin="80px 0 0 25px"
						width="33%"
						sm-align-self="center"
						sm-margin="50px 0 0 15px"
					>
						<Image
							src="https://smartwaysbin.com/img/3.jpg"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
						/>
						<Image
							src="https://smartwaysbin.com/img/1.jpg"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						flex-direction="column"
						margin="0 0 0 25px"
						width="33%"
						justify-content="flex-start"
						sm-margin="0 0 0 15px"
					>
						<Image
							src="https://smartwaysbin.com/img/4.jpg"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
						/>
						<Image
							src="https://smartwaysbin.com/img/5.jpg"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://smartwaysbin.com/img/6.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Чому варто обрати Surf's Up Camp?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					<Strong>
					Інструктори-експерти: 
					</Strong>
					{" "}- Наша команда складається з сертифікованих професіоналів з багаторічним досвідом. Вони надають індивідуальні тренування, щоб допомогти вам швидко та безпечно вдосконалюватися.
					<br />
					<br />
					<Strong>
					Першокласне спорядження: 
					</Strong>
					{" "}- Ми надаємо найкращі дошки та спорядження, щоб у вас було все необхідне для фантастичного серфінгу.
					<br />
					<br />
					<Strong>
					Приголомшливе розташування: 
					</Strong>
					{" "}- Наш табір розташований в місці, відомому своїми стабільними хвилями і красивими околицями, що робить кожну сесію серфінгу справжньою насолодою.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Про Surf's Up Camp
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Розташований у незайманому місці з ідеальними хвилями та захоплюючими краєвидами, Surf's Up Camp - це ідеальне місце для серфінгу. Наш табір створений для серфінгістів усіх рівнів, від тих, хто тільки починає, до тих, хто катається на хвилях роками. Ми пишаємося нашими доброзичливими та досвідченими інструкторами, які з пристрастю діляться своєю любов'ю до серфінгу.

					<br />
					<br />
					
Наш табір пропонує унікальне поєднання кваліфікованих тренерів, високоякісного обладнання та дружньої спільноти. Ми віримо, що серфінг - це не просто спорт, а спосіб життя. Приєднуйтесь до нас, щоб відкрити для себе радість і свободу, які приходять з катанням на ідеальній хвилі. Незалежно від того, приїхали ви на вихідні чи на тиждень, ви поїдете звідси з новими навичками, новими друзями та спогадами, які залишаться на все життя.

				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://smartwaysbin.com/img/7.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});